<!-- src/views/MyProjects.vue -->
<template>
  <div class="projects">
    <h1>我的一些项目</h1>
    <!--div class="project-list">
      <div class="project-card" v-for="n in 3" :key="n">
        <h3>项目 {{ n }}</h3>
        <p>这里是对项目 {{ n }} 的一些介绍</p>
      </div>
    </div-->
    <div class="projtct-card">
      <div class="project-card">
        <h3><a href="https://github.com/HenryMoreau/low-code-homepage" style="color: #42b983">低代码个人主页</a></h3>
        <p>我亲手写的第一个项目<br>完全使用HTML编写，上手简单<br>经历多次迭代后具有完整的功能，如深色模式等</p>
      </div>
    </div>
    <div class="projtct-card">
      <div class="project-card">
        <h3><a href="https://github.com/HenryMoreau/low-code-homepage" style="color: #42b983">低代码个人主页</a></h3>
        <p>我亲手写的第一个项目<br>完全使用HTML编写，上手简单<br>经历多次迭代后具有完整的功能，如深色模式等</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyProjects'
};
</script>

<style scoped>
.projects {
  background-color: #e0f7fa;
  padding: 20px;
  border-radius: 8px;
}

.project-list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.project-card {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;
}

h3 {
  color: #42b983;
}
</style>
