<!-- src/views/HomePage.vue -->
<template>      
  <h1 style="font-size: 3rem;">👋</h1><!--第一部分，大标题-->
  <h1 style="font-size: 3rem;">有朋自远方来，不亦乐乎！<br>我是 Henry Moreau
  </h1>
  <div class="home">
    <h1>欢迎来到本站！</h1>
    <p>这里是 Henry 初学 Vue 之后搭建的一个小网站。</p>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>

<style scoped>
.home {
  background-color: #e3f2fd;
  padding: 20px;
  border-radius: 8px;
}
</style>
