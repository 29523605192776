<!-- src/App.vue -->
<template>
    <div id="app">
      <div class="sidebar">
        <router-link to="/">主页</router-link>
        <router-link to="/about">关于我</router-link>
        <router-link to="/projects">项目</router-link>
        <a href="https://blog.henrywhu.cn" target="_blank">博客→</a>
      </div>
      <div class="main-content">
        <router-view/>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App'
  };
  </script>
  
  <style>
  /* 全局设置字体 */
  * {
    font-family: 'Noto Serif SC', serif;
    font-weight: bold;
  }
  
  /* 侧边栏样式 */
  #app {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 200px;
    background-color: #35495e;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .sidebar a {
    color: white;
    text-decoration: none;
    margin: 10px 0;
    font-size: 18px;
    transition: 0.3s;
  }
  
  .sidebar a:hover {
    color: #42b983;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
  }
  
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #35495e;
  }
  
  p {
    font-size: 1.2em;
    line-height: 1.5;
    color: #2c3e50;
  }
  
  /* 响应式 */
  @media (max-width: 768px) {
    #app {
      flex-direction: column;
    }
  
    .sidebar {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
    }
  
    .main-content {
      padding: 10px;
    }
  }
  </style>
  