<!-- src/views/AboutPage.vue -->
<template>
  <div class="about">
    <h1>关于我的点点滴滴</h1>
    <div class="card">
      <p>土生土长的重庆学生一枚，受朋友<a href="" style="color: #000"><b>Kegongteng</b></a>影响开始热衷网站前端研究。他也是我<a href="https://friends.henrywhu.cn" style="color: #000"><b>特别感谢名单</b></a>中的第一位。</p>
      <p>目前还在读高中，在准备参加2025高考。</p>
      <p>在编程方面属于刚刚入门，会HTML、CSS和Python，但是都不是很多，目前正在学习使用Vue，很多地方都不太熟悉，请见谅。</p>
    </div>
  </div>
  <div class="about">
    <h1>关于这个页面</h1>
    <div class="card">
      <p>本页的制作灵感来源于<a href="https://zhilu.cyou" style="color: #000" target="_blank">纸鹿</a>的主页，本着初生牛犊不怕虎的原则，自己再次手搓了这么一个简陋的主页。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage'
};
</script>

<style scoped>
.about {
  background-color: #fff3e0;
  padding: 20px;
  border-radius: 8px;
}

.card {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
